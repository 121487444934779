<template>
  <div class="container">
    <div class="row mt-4 mb-5">
      <div class="col-md-12 offset-md-2">
        <div class="actions">
          <router-link tag="secondaryButton" class="btn-sm hidden-print" :to="{ name: 'dashboard' }">
            <i class="material-icons">home</i>
          </router-link>
          <primary-button @click="print" class="btn-sm hidden-print ml-2">
            <i class="material-icons">print</i>
          </primary-button>
        </div>
      </div>

      <div class="row mt-4 mb-2">
        <div class="col-md-12" v-html="privacyText"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  created() {
    let language = this.company.languages ? this.company.languages.find((e) => e.default === true) : false;
    language = language ? language.localeCode : false;
    if (
      window.localStorage.getItem('onboarding_user') !== null &&
      JSON.parse(window.localStorage.getItem('onboarding_user')).language
    ) {
      language = JSON.parse(window.localStorage.getItem('onboarding_user')).language;
    }
    if (
      window.localStorage.getItem('onboarding_user') !== null &&
      JSON.parse(window.localStorage.getItem('onboarding_user')).language
    ) {
      language = JSON.parse(window.localStorage.getItem('onboarding_user')).language;
    }
    if (window.localStorage.getItem('invite-user-language')) {
      language = window.localStorage.getItem('invite-user-language');
    }
    this.$http
      .get(`/companies/${this.company.id}/privacy/${language ? '?Language='.concat(language) : ''}`)
      .then((result) => {
        this.privacyText = result.data.privacyDisclaimerHTML;
        setTimeout(() => {
          var privacyPolicy = document.getElementsByClassName('privacy-policy')[0];
          privacyPolicy.className += ' row';
        }, 1);
      });
  },
  computed: {
    ...mapGetters('settings', ['company'])
  },
  data() {
    return {
      privacyText: ''
    };
  },
  methods: {
    print() {
      window.print();
    }
  }
};
</script>

<style scoped>
h5 {
  font-weight: bold;
  margin-top: 18px;
}

button {
  display: inline-flex;
  border: none !important;
  align-items: center;
  justify-content: center;
  padding: 6px 9px;
}

.actions {
  margin-left: 75%;
}

.row {
  width: 100%;
}
</style>

<style>
.privacy-policy {
  width: 100%;
  padding: 5px;
}
</style>
